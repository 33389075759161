import React from "react";
import { IonSelectOption } from "@ionic/react";

interface ProceduresType {
  value: string;
  label: string;
}

interface LocationsType {
  value: string;
  label: string;
}

export const getProcedures = () => {
  let procedures: ProceduresType[] = [];

  procedures = [
    { value: "DPA", label: "DPA" },
    { value: "DPAC", label: "DPAC" },
    { value: "DPAP (pedriatria)", label: "DPAP (pedriatria)" },
    { value: "HD", label: "HD" },
    { value: "SLED", label: "SLED" },
    { value: "UF ISOLADA", label: "UF ISOLADA" },
    { value: "VISITA HOSPITALAR", label: "VISITA HOSPITALAR" },
  ];

  return procedures.map((procedure) => (
    <IonSelectOption key={procedure.value} value={procedure.value}>
      {procedure.label}
    </IonSelectOption>
  ));
};

export const getLocations = () => {
  let locations: LocationsType[] = [];

  locations = [
    { value: "UTI", label: "UTI" },
    { value: "ENFERMARIA", label: "ENFERMARIA" },
    { value: "OBSERVAÇÃO", label: "OBSERVAÇÃO" },
    { value: "EMERGÊNCIA", label: "EMERGÊNCIA" },
    { value: "OUTROS", label: "OUTROS" },
  ];

  return locations.map((location) => (
    <IonSelectOption key={location.value} value={location.value}>
      {location.label}
    </IonSelectOption>
  ));
};

export const getType = (proc: string) => {
  if (["CRRT"].includes(proc)) {
    return "CONT";
  } else if (["DPA", "DPAC", "DPAP (pedriatria)", "DPI"].includes(proc)) {
    return "DP";
  } else {
    return "DEFAULT";
  }
};
