import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  formValueSelector,
} from "redux-form";
import {
  IonCard,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonSelectOption,
} from "@ionic/react";

import api from "../../services/api";

import Main from "../../components/Main";
import Input from "../../components/Legacy/Form/Input";
import InputMask from "../../components/Legacy/Form/InputMask";
import HospitalSearch from "../../components/Legacy/Search/Hospital";
import AgreementSearch from "../../components/Legacy/Search/Agreement";
import Select from "../../components/Legacy/Form/Select";
import { create } from "../../store/patient/actions";
import { Patient as PatientType } from "../../store/patient/types";

import { RootState } from "../../store";

interface StateProps {
  patientForm: PatientType["hospital"];
}

interface DispatchProps {
  create(values: PatientType): void;
}

type Props = StateProps & DispatchProps;

const Patient: React.FC<Props & InjectedFormProps<PatientType, Props>> = ({
  handleSubmit,
  create,
  change,
  patientForm,
}) => {
  const [phoneMask, setPhoneMask] = useState("(99) 99999-9999");

//   useEffect(() => {
//     if (!!patientForm?.value) {
//       api.get(`doctor/patient/agreement/${patientForm.value}`).then((resp) => {
//           if(!!resp.data)
//             change("agreement", resp.data);
//       });
//     }
//   }, [change, patientForm]);

  return (
    <Main title="Novo Paciente" back>
      <IonCard>
        <IonCardContent>
          <form onSubmit={handleSubmit(create)}>
            <IonGrid>
              <IonRow>
                <Field name="name" component={Input} label="Nome" />
                <Field name="hospital" component={HospitalSearch} />
                <Field name="agreement" component={AgreementSearch} />
                <Field name="enrollment" component={Input} label="Matrícula" />
                <Field
                  name="birthdate"
                  mask="99/99/9999"
                  component={InputMask}
                  label="Data de Nascimento"
                />
                <Field name="sex" component={Select} label="Sexo">
                  <IonSelectOption value="M">Masculino</IonSelectOption>
                  <IonSelectOption value="F">Feminino</IonSelectOption>
                </Field>
              </IonRow>
              <IonRow className="ion-margin-top">
                <IonCol size="6">
                  <IonButton type="submit" color="primary" expand="block">
                    Salvar
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </form>
        </IonCardContent>
      </IonCard>
    </Main>
  );
};

const PatientForm = reduxForm<PatientType, Props>({
  form: "patient",
})(Patient);

const selector = formValueSelector("patient");

const mapStateToProps = (state: RootState) => ({
  patientForm: selector(state, "hospital"),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ create }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PatientForm);
